import GreensterLogo from "@kiosk/front/assets/img/greenster.svg"

import { Branding } from "../lib/entities/company"

import KioskLogo from "./logo.svg"

interface LogoProps {
  readonly height?: number
  readonly branding: Branding
}

const logoComponents: Record<Branding, string> = {
  KIOSK: KioskLogo,
  GREENSTER: GreensterLogo,
}

export default function Logo(props: LogoProps) {
  const source = logoComponents[props.branding]

  return <img height={props.height ?? 28} src={source} />
}
