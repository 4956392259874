/**
 * Configuration for the frontend app.
 *
 * There are several ways to set configuration options for the application:
 * 1) Hardcoded
 *    ---------
 *    This is the simplest approach.
 *
 *    Use it for things like constants that don’t need to change based
 *    on the environment.
 *
 *    For example: defaultTopicId: "1234"
 *
 * 2) Runtime
 *    -------
 *    A runtime value can be set with regular environment variables.
 *    They can only be accessed on the server, but can be passed to the
 *    frontend using this pattern: https://remix.run/docs/en/main/guides/envvars
 *
 *    Use this for options that we want to set on the fly.
 *
 *    For example: the URL of some service or feature flags.
 *
 * 3) Build-time
 *    ----------
 *    A build-time value can be set with `import.meta.env.MY_VARIABLE`.
 *    Vite will set this value at *build time* based on the environment
 *    it has access to.
 *
 *    Use this for things that *need* to be set at build time.
 *    This should be rather rare, but who knows.
 *
 *    Note: this means that we need to *rebuild* the application for the
 *          changes to take effect.
 *
 *    Note: since it’s build-time, it can also be used to set values on
 *          the frontend. For that, the variable needs to be prefixed with
 *          `VITE`.
 *          For example: `logo: imports.meta.env.VITE_IS_KIOSK`.
 *          The client code can then access this configuration value.
 *
 * Because of these different ways, the configuration is split into two files:
 * - app/config.client.ts (only accessed on the client)
 * - app/config.server.ts (only accessed on the server)
 *
 * This makes it obvious what is accessible where and helps enforcing patterns.
 */

import { Option } from "@kiosk/shared/utils/helpers"

function getEnv(key: string): Option<string> {
  return import.meta.env[key]
}

function requireEnv(key: string): string {
  const value = getEnv(key)

  if (!value) {
    throw new Error(`Expected environment variable: ${key}`)
  }

  return value
}

// TODO: progressively move these to the window of the application so that
//       they can be dynamically set by the server.

export const clientConfig = {
  apiUrl: requireEnv("VITE_API_URL"),
  isDemo: getEnv("VITE_IS_DEMO") === "true",
  enableAICalls: getEnv("VITE_ENABLE_AI_CALLS") === "true",
  disclosureRequirements: {
    iro2: "FEsb2ApWRsWDzuF_cWdPpw",
  },
  esrs: {
    default: "VGDB5PoRTB2Lm5YWHf5qSQ",
  },
  sentry: {
    dsn: getEnv("VITE_SENTRY_DSN"),
    tracesSampleRate: 1.0,
  },
}
